@import 'settings';
@import "variables";

@mixin animate($duration: var(--animation-duration), $easing: var(--animation-easing)) {
  transition: all $duration $easing;
}

@mixin invisible-scrollbar {
  &::-webkit-scrollbar {
    display: none;
    background: transparent;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin font-face($fontFamily, $fontPath) {
  @font-face {
    font-family: '#{$fontFamily}';
    src: url('#{$fontPath}/#{$fontFamily}.eot');
    src: url('#{$fontPath}/#{$fontFamily}.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}/#{$fontFamily}.woff') format('woff'),
    url('#{$fontPath}/#{$fontFamily}.woff2') format('woff2'),
    url('#{$fontPath}/#{$fontFamily}.ttf') format('truetype'),
    url('#{$fontPath}/#{$fontFamily}.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}