@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/css/mq";
@import "variables";

:root {
  --primary: #{$jakalaprimary};
  --secondary: #{$jakalasecondary};
  --tertiary: #{$jakalatertiary};
  --default: #{$jakalatertiary};
  --background-selection: #{$white};
  --color-primary: #{$color-primary};
  --color-selection: #{$color-selection};
  --red: #{$red-color};
  --danger: #{$red-color};
  --gray-100: #{$gray-100};
  --gray-200: #{$gray-200};
  --gray-300: #{$gray-300};
  --gray-400: #{$gray-400};
  --gray-500: #{$gray-500};
  --gray-600: #{$gray-600};
  --gray-700: #{$gray-700};
  --gray-800: #{$gray-800};
  --color-primary-light: #{$color-primary-light};
  --color-primary-light-lighter: #{$color-primary-light-lighter};
  --color-primary-dark: #{$color-primary-dark};

  --spacing: 3rem;
  --spacing-m: 2rem;
  --spacing-s: 1rem;
  --spacing-xs: .5rem;
  --spacing-xxs: 0.25rem;

  --fs-default: 16px;
  --fs-xxl: 42px;
  --fs-xl: 28px;
  --fs-l: 24px;
  --fs-m: 20px;
  --fs-s: 18px;
  --fs-xsm: 14px;
  --fs-xs: 12px;

  --fs-weight--standard: 400;
  --fs-weight--bold: 700;
  --fs-weight--bolder: 900;

  --shadow-default: 0 6px 17px 0 rgba(0, 0, 0, 0.25);

  --animation-duration: .25s;
  --animation-easing: ease-in-out;

  --size-container: 1280px;
  --size-container-m: 848px;

  --btn-roundness: 0.5rem;

  --subnav-color: #{$subnavmenu};


  --color-tints: #{$color-tints};

  --color-secondary: #{$jakalasecondary};
  --color-tertiary: #{$jakalatertiary};

  --header-text-color: #{$text-color};

  --color-darker: #{$color-darker};
  --color-dark: #{$color-dark};
  --color-dark-light: #{$color-dark-light};
  --color-dark-lighter: #{$color-dark-lighter};
  --color-dark-disabled: #{$color-dark-disabled};

  --color-danger: #{$color-danger};
  --color-danger-light: #{$color-danger-light};
  --color-danger-dark: #{$color-danger-dark};

  --color-warning: #{$color-warning};
  --color-warning-light: #{$color-warning-light};
  --color-warning-dark: #{$color-warning-dark};

  --color-success: #{$color-success};
  --color-success-light: #{$color-success-light};
  --color-success-dark: #{$color-success-dark};

  --color-white: #{$white};
  --color-black: #{$black};
  --color-yellow: #{$yellow};
  --color-blue: #{$blue};
  --color-lightblue: #{$light-blue};

  --gray-300-trasparent: rgba(214, 214, 214, 0.64);

  --main-gradient: linear-gradient(56.97deg, var(--color-primary-light) 59.74%, var(--color-primary-light-lighter) 128.26%);



  @include mq($until: desktop) {
    --fs-xl: 20px;
  }
}