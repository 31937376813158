@import "assets/css/mq";

.pagination-wrapper {
	margin-top: var(--spacing-m);

	@include mq($until: desktop) {
		margin-top: var(--spacing-s);
	}
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;

	& > * {
		display: flex;
		line-height: 1px;
		width: 24px;
		height: 22px;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 3px;
		background: var(--color-primary);
		border: 1px solid var(--color-primary);
		color: var(--color-primary-dark);

		& + * {
			margin-left: .5rem;
		}
	}

	&__btn {
		border: 0;
		color: var(--white);
		background: var(--color-primary-dark);
		&.active {
			background: var(--secondary);
			border: 1px solid var(--color-primary-dark);
			color:  var(--color-white);
		}

		a {
			&:hover {
				color:  var(--white);
			}
		}
	}

	&__link {
		font-size: 12px;
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
		color: var(--color-white);

		&a {
			&:hover {
				color:  var(--primary);
			}
		}

	}

	&__dots {
		background: none;
		color: var(--color-primary);
		font-weight: 900;
		letter-spacing: 1px;
		border: 0;
	}
}