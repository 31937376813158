@import "assets/css/mq";

.navbar-logo {
  height: 3rem;
}

.bg-nav-primary {
  background-color: var(--color-tints)
}

.bg-submenu {
  background-color: var(--subnav-color);
}

.sub-nav {
  @include mq($until: tablet) {
    display: none !important;
  }
}

.toggler-button {
  @include mq($from: tablet) {
    display: none;
  }
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid var(--secondary);
  border-radius: 0.25rem;
  transition: all 200ms;
  position: absolute;
  right: -1rem;
  bottom: 2rem;


  /*  @include mq($until: mobile) {
      right: -17rem;
    }

    @include mq($until: tablet) {
      right: -17rem;
    }

    @include mq($until: desktop) {
      right: -31rem;
    }*/

  &__icon {
    stroke: var(--secondary);
    height: 2rem;
  }
}

.nav-header {
  text-transform: uppercase;
  color: var(--color-white);
  font-weight: var(--fs-weight--bold);
  padding-top: 0.6rem;

  &__title {
    padding-left: 2rem;
    font-size: var(--fs-xl);
  }

  &__icon-container {
    &__icon {
      stroke: var(--color-white);
      height: 2.5rem;
      cursor: pointer;
    }

    margin-left: auto;
  }
}

.sub-item {
  padding: 0.1rem 0.9rem !important;
}

.nav-logos-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  align-self: center;

  .navbar-brand-right {
    justify-self: right;
    grid-column: 3;
  }

  .navbar-brand-right-f {
    justify-self: right;
    grid-column: 3;
  }

  .navbar-brand {
    justify-self: center;
    grid-column: 2;
  }
  @include mq($until: tablet) {
    .navbar-brand-right {
      justify-self: left;
    }
  }
}

.nav-link {
  color: var(--color-white);
  text-transform: uppercase;
  font-weight: var(--fs-weight--bold);
}

.nav-link:hover,
.nav-link:active {
  color: var(--color-primary);
}

/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 20rem;
  position: fixed; /* Stay in place */
  z-index: 100;
  top: 0; /* Stay at the top */
  right: 0;
  transform: translateX(101%);
  background: var(--main-gradient);
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.2s; /* 0.5 second transition effect to slide in the sidenav */
  .nav-link {
    border-bottom: 1px solid rgb(198, 199, 206);
  }
}

.showSideNav {
  transform: translateX(0);
}

.sidenav-list {
  padding: 1rem;
}

/* The navigation menu links */
.sidenav a {
  display: flex;
  flex-direction: column;
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.top-nav {
  &__icon {
    stroke: var(--white);
    height: 1.5rem;
  }
}

.cart-badge-side {
  top: 0.4rem;
  right: 7.5rem;
  background-color: var(--secondary);
}

.cart-badge {
  top: 2px;
  right: 3px;
  background-color: var(--secondary);
}

.backdrop-pannel {
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, .5);
  bottom: 0;
  position: absolute;
  top: 0;
  transition: all .15s linear;
  width: 100vw;
  z-index: 98;
  display: none;
}

.backdrop-pannel-show {
  display: block;
}

.nav-link {
  color: var(--white);
  font-weight: 700;
}

.custom-navbar{
  //position: absolute !important;
  //right: 70px !important;
  //top: 50px !important;
  padding: 0 !important;
}