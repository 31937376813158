.form-wrapper {
	width: 620px;
	max-width: 100%;
	margin: auto;
}

.form {
	--form-spacing: 1.5rem;
	
	& * {
		font-family: inherit;
	}
	
	&--size-m {
		max-width: 680px;
		--form-spacing: 1.25rem;
	}
	
	&--size-s {
		max-width: 560px;
		--form-spacing: 1rem;
	}
	
	max-width: 100%;
	background: #fff;
	padding: var(--form-spacing);
	box-shadow: 0 20px 20px -16px rgba(0, 0, 0, .25), 0 0 12px rgba(0, 0, 0, .1);
	
	&__row {
		display: flex;
		width: 100%;
		margin-top: var(--form-spacing);

		&:first-of-type {
			margin-top: 0;
		}

		& + & {
			//margin-top: var(--form-spacing);
		}
	}
	
	&__object {
		display: flex;
		flex-direction: column;
		width: 100%;


		.multiple & {
			
			& + div {
				margin-left: 1rem;
			}
		}
		
		&__error {
			margin-top: .5rem;
			line-height: 1;
			
			& ul {
				list-style-type: none;
				display: flex;
				flex-direction: column;
			}
			
			& li {
				position: relative;
				display: flex;
				align-items: center;
				color: #eb5050;
				font-weight: 500;
				font-size: 13px;
				line-height: 1.1;
				
				& + & {
				
				}
				
				&::before {
					content: 'Errore';
					background: #eb5050;
					color: #fff;
					font-weight: normal;
					font-size: 12px;
					line-height: 1;
					margin-right: .25rem;
					padding: 3px 4px;
					border-radius: 3px;
					letter-spacing: .25px;
				}
			}
		}
	}
	
	&__label {
		display: flex;
		align-items: center;
		margin-bottom: .5rem;

		
		& label {
			text-transform: uppercase;
			font-size: 13px;
			font-weight: 900;
			letter-spacing: .25px;
			line-height: 1.2;
			color: #1f2e40;
		}
	}
	
	&__element {
	
	}
	
	&__input {
		width: 100%;
		outline: 0;
		padding: .75rem;
		border-radius: 3px;
		border: 0;
		box-shadow: 0 0 0 2px black !important;
		background: #fff;
		color: #445a73;
		line-height: 1.2;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: .25px;

		&:hover,
		&:focus {
			color: #052850;
			box-shadow: 0 0 0 4px green !important;
		}
	}
	
	&__btn {
		
		&:hover,
		&:focus {
		
		}
	}
	
	& textarea {
		resize: vertical;
		height: 120px;
	}
}