.form-content {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	margin: 0 auto;
	padding: 2.25rem 2rem;
	border-radius: 8px;
	background: #f0f0f0;

	&__row {
		@media all and (min-width: 400px) {
			display: flex;
			flex-direction: column;
		}

		&.horizontal {
			flex-direction: row;
			justify-content: space-between;
		}

		& + & {
			margin-top: 1.5rem;
		}

		&.with-radio-choice {
			flex-direction: row;
			align-items: center;
			margin-bottom: -.5rem;

			& > strong {
				font-weight: 900;
				margin-right: 2rem;
				font-size: 1.5rem;
			}
		}

		& > h3 {
			font-size: 1.25rem;
			font-weight: 900;
			line-height: 1;
			margin-bottom: 2rem;
		}
	}

	&__object {
		display: flex;
		flex-direction: column;
		width: 100%;

		.horizontal & {
			width: initial;
			flex: 1;

			& + div {
				@media all and (min-width: 400px) {
					margin-left: 2rem;
				}
			}
		}

		.with-radio-choice & {
			flex-direction: row;
			flex: 1;
		}

		&__error {

			& ul {
				display: flex;
				flex-direction: column;
				list-style: none;
				padding: 0;
				margin: .75rem 0 0 1px;
				align-items: flex-start;

				.horizontal & {
					align-items: initial;
					min-width: 100%;
				}

				& li {
					display: inline-flex;
					background: #da4179;
					line-height: 1.1;
					padding: 4px 8px 5px;
					border-radius: 2px;
					font-weight: bold;
					font-size: 12px;

					& + li {
						margin-top: .35rem;
					}
				}
			}
		}
	}

	&__label {
		display: flex;
		line-height: 1;
		margin-bottom: .75rem;
		font-weight: bold;

		.is-auth-form & {
			justify-content: center;
			width: 100%;
			font-size: 1.5rem;
			margin-bottom: 1rem;
		}
	}

	&__field {
		width: 100%;

		.with-radio-choice & {
			width: initial;
			display: flex;
			align-items: center;
			justify-content: center;

			& + div {
				margin-left: 1rem;
			}

			& input {
				width: initial;
				position: relative;
				margin-top: 1px;

				&:checked + label {
					color: #0bbbef;
					box-shadow: 0 3px 0 0 #0bbbef
				}
			}

			& label {
				font-weight: bold;
				color: #d0d0d0;
				margin: 0 0 0 .5rem;
				cursor: pointer;
				transition: all .25s ease-in-out;
			}
		}
	}

	&__input {
		width: 100%;
		padding: .75rem 1rem .8rem;
		border: 0 !important;
		border-radius: 4px;
		background: #fff;
		font-weight: 500;
		line-height: 1;
		outline: none !important;
		color: #666 !important;
		transition: all .25s ease-in-out;
		box-shadow: 0 0 0 3px #afafaf;
		font-size: 15px;

		@media all and (max-width: 399px) {
			margin-bottom: 10px;
		}

		&:hover,
		&:focus {
			&:not(input[type=radio]) {
				background: #fff;
				box-shadow: 0 0 0 4px #5da3e4;
			}
		}
	}

	& textarea {
		height: 180px;
		line-height: 1.4;
		font-weight: normal;
		font-size: 1rem;
	}

	&__prizes {

		& > p {
			line-height: 1.8;
			margin-bottom: 0;
			font-size: 1.2rem;

			& + p {
				margin-top: .75rem;
			}
		}
	}

	& .form-btn {
		background: #60a3e1;
		color: #fff;

		&:hover,
		&:focus {
			background: #3581c7;
		}
	}
}