.btn {
	display: inline-flex;
	font-weight: 500;
	font-size: 14px;
	padding: .5rem 1rem;
	border: 2px solid;
	border-radius: 4px;
	text-align: center;
	justify-content: center;
	align-items: center;
	letter-spacing: .5px;
	min-width: 110px;
	outline: none;
	cursor: pointer;
	
	&.rounded {
		border-radius: 40px;
	}
	
	&__icon {
		margin-right: .5rem;
		
		.btn--size-small & {
			margin-right: .25rem;
		}
	}
	
	&--size-small {
		font-size: 12px;
		padding: .35rem .75rem;
		min-width: 80px;
	}
	
	&:hover,
	&:focus {
		color: var(--color-primary-dark);
		background-color: #c7d6e7;
	}
	
	&--primary {
		color: var(--color-primary);
		
		&.filled {
			background-color: var(--color-primary);
			border-color: var(--color-primary-dark);
			color: #fff;
			
			&:hover,
			&:focus {
				background-color: var(--color-primary-dark);
				border-color: var(--color-primary-dark);
			}
		}
	}
	
	&--secondary {
		color: var(--color-secondary);
		
		&.filled {
			background-color: var(--color-secondary);
			border-color: var(--color-secondary-dark);
			color: #fff;
			
			&:hover,
			&:focus {

			}
		}
	}
	
	&--blank {
		background-color: #e0e0e0;
		color: #909090;
		
		&.filled {
			background-color: #e0e0e0;
			border-color: darken(#e0e0e0, 5%);
			color: #707070;
			
			&:hover,
			&:focus {
				background-color: #d0cece;
				border-color: #b9b8b8;
			}
		}
	}
	
	&--success {
		color: var(----color-success);
		
		&:hover,
		&:focus {
			background-color: #acf1e1;
		}
		
		&.filled {
			background-color: var(----color-success);
			color: #fff;
			
			&:hover,
			&:focus {
			}
		}
	}
	
	&--warning {
		color: var(--color-warning);
		
		&:hover,
		&:focus {
			background-color: #f1e4b3;
		}
		
		&.filled {
			background-color: var(--color-warning);
			color: #fff;
			
			&:hover,
			&:focus {
			}
		}
	}
	
	&--danger {
		color: var(--color-danger);
		
		&:hover,
		&:focus {
			background-color: #ffdcdc;
		}
		
		&.filled {
			background-color: var(--color-danger);
			color: #fff;
			
			&:hover,
			&:focus {
			}
		}
	}
}