@import "~bootstrap/scss/bootstrap";
@import "assets/css/settings";
@import "assets/css/normalize";
@import "assets/css/utils";
@import "assets/components/form/forms";
@import "assets/components/form/order-form";
@import "assets/components/buttons/buttons";
@import "assets/components/pagination/pagination";

/* Sticky footer styles
-------------------------------------------------- */

html {
	position: relative;
	min-height: 100%;
	font-family: 'Roboto', sans-serif;
}

body {
	margin-bottom: 60px;
	font-family: 'Roboto', sans-serif;
}

.main {
	flex: 1;
	display: flex;
	flex-direction: column;
	max-width: 100%;
}

.app {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.app-header {
	background: rgba(255, 255, 255, .4);
	border-bottom: 4px solid rgba(255, 255, 255, .25);
	padding: 1rem;

	& .contain {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media all and (max-width: 768px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
}

.app-footer {
	background: rgba(0, 0, 0, .95);
	padding: 2rem;

	& p {
		color: #fff;
		font-size: 14px;
		text-align: center;
	}
}

.page-content {

	//margin-top: 9rem;

	@include mq($until: tablet) {
		//margin-top: 6.5rem;
	}
}

.container-global {
	background: var(--white);
}

.table-color-primary {
	background-color: var(--color-primary-light);
	color: var(--color-primary-dark);
}

.card {
	box-shadow: 0 0 0.4rem 0 #18181873;
	overflow: hidden;

	.card-body {
		height: 6rem;
		padding: 0 0.2rem;
	}

	&__card-text-body {
		font-size: .95rem;
		font-weight: 700;
	}
}

.card-image {
	height: 100%;
	object-fit: cover;
}

.card--full {
	height: 100% !important;
}

.card--pointer {
	cursor: pointer !important;
}

.title {
	display: flex;
	flex-direction: column;
	color: var(--color-black);
	text-align: center;
	align-items: center;
	justify-content: center;
	margin-bottom: 3rem;

	&__primary {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 2rem;
		line-height: 1;
	}

	&__secondary {
		font-size: 1.1rem;
		line-height: 1;
		margin-top: 0.5rem;
		padding: 0 0.5rem;
	}
}

.form {

	&__object {

		&__error {

			& li {
				color: #052850;

				&::before {
					background: #1f2e40;
					color: #fff;
				}
			}
		}
	}

	&__label {
		color: black; // was #fff
		text-transform: uppercase;
		font-weight: bold;
		font-size: 13px;
		letter-spacing: .25px;
	}

	&__input {
		box-shadow: none;
		border-radius: 4px;
		padding: 1rem;

		&:hover,
		&:focus {
			box-shadow: 0 0 0 3px #217360;
		}
	}

	&-btn {
	}
}

.contain {
	width: 1024px;
	max-width: 100%;
	margin: 0 auto;

	&.size-large-xl {
		width: 1200px;
	}
}

.user-info {
	margin-top: 1rem;
	display: block;
	box-shadow: 0 3px 20px 0 #072c172e;
	//background-color: var(--subnav-color);
	color: var(--color-primary-dark);
	padding: 2rem 0;

	&__link:link,
	&__link:visited
	{
		color: var(--color-primary-dark);
	}

	&__content {
		display: block;
		padding: 0.5rem;
		justify-content: flex-start;
		gap: 1.6rem
	}

	&__icon {
		font-size: 3rem;
		color: var(--color-primary-dark);
	}

	&__points,
	&__hello {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.4rem;
		font-size: 1.8rem;
		text-transform: uppercase;
	}

	&__name_data {
		display: none;
	}

	&__points {
		&__text {
			margin: 0;
			padding: 0;
		}

		&__balance {
			font-weight: bold;
			margin: 0;
			padding: 0;
		}
	}
}


/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

//body > .container {
//	padding: 5rem 1rem 1rem;
//
//	@include media-breakpoint-up($chosen-breakpoint-sm) {
//		padding: 5rem 1rem 2rem 1rem;
//	}
//}

.footer {

	background: var(--color-primary-light);
	padding: 1.5rem;
	font-size: 80%;
	font-weight: 700;

	a {
		color: var(--color-white);
	}

	.contain > p {
		text-align: left;
		margin-bottom: 0;
		width: 100%;
	}

	.contain > p > span {
		margin-left: 1rem;
	}
	.contain> p > span > a{
		float: right;
	}
}

code {
	font-size: 80%;
}

.block-access-color {
	color: var(--color-black);
}

////GLOBALS
//input {
//	border: none !important;
//	outline: none !important;
//	border-bottom: 1px solid var(--secondary) !important;
//	border-radius: 0 !important;
//
//	&.has-error {
//		border-bottom: 1px solid red !important;
//	}
//}
//
//.has-error-message {
//	position: absolute;
//
//	ul {
//		padding: 0;
//		list-style-type: none;
//		color: var(--danger);
//		font-size: 11px;
//		font-weight: 500;
//	}
//}
//
//::selection {
//	background: var(--background-selection);
//	color: var(--color-selection);
//}
//
//.form-control:disabled, .form-control[readonly] {
//	background-color: transparent;
//	opacity: 1;
//}
//
//input:focus, textarea:focus {
//	border-color: $green !important;
//}
//
//input[type=radio].has-error {
//	outline: 1px solid red !important;
//}
//
//.form-control {
//	box-shadow: none !important;
//}
//
//hr {
//	border-top: 2px solid $black;
//}
//
//.img-logo {
//	max-width: 200px;
//	width: 100%;
//	height: auto;
//}
//
//.img-brand {
//	display: block;
//	margin: 1rem auto 2rem auto;
//	width: 100%;
//	max-width: 300px;
//	height: auto;
//}
