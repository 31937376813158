.d {

	&--none {
		display: none;
	}

	&--block {
		display: block;
	}

	&--inline-block {
		display: inline-block;
	}

	&--flex {
		display: flex;
	}

	&--inline-flex {
		display: inline-flex;
	}
}

.justify {

	&--center {
		justify-content: center;
	}

	&--between {
		justify-content: space-between;
	}
}

.align {

	&--start {
		align-items: flex-start;
	}

	&--end {
		align-items: flex-end;
	}

	&--center {
		align-items: center;
	}

	&--stretch {
		align-items: stretch;
	}
}

.center.center {
	text-align: center;
}