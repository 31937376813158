@import "./assets/css/mq";
@import "./assets/css/mixins";

.app-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  background: rgba(0, 0, 0, .75);
  overflow-y: scroll;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  backdrop-filter: blur(5px);
  @include animate;
  @include invisible-scrollbar;

  &.prevent-backdrop-close {
    cursor: initial;
  }

  &.page-loaded {
    display: flex;
  }

  &.show {
    opacity: 1;
    z-index: 900;
  }

  &__inner {
    cursor: default;
    background: #fff;
    width: 100%;
    min-height: 15rem;
    box-shadow: var(--shadow-default);
    transition: all var(--animation-duration) var(--animation-easing);
    border-radius: 6px;
    overflow-x: hidden;
    @include invisible-scrollbar();

    &-wrapper {
      position: relative;
      width: 548px;
      max-width: 84%;
      max-height: 84%;
      display: flex;

      .size--default & {
        width: 548px;
      }

      .size--small & {
        width: 414px;
      }

      .size--medium & {
        width: 768px;
      }

      .size--large & {
        width: 1024px;
      }

      .size--wide & {
        width: 1280px;
      }
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -2rem;
    right: 0;
    background: none;
    border: 0;
    color: #666;
    cursor: pointer;
    z-index: 111;

    &--icon {
      margin: 0;
      max-width: 20px;
      max-height: 20px;
    }
  }

  &__title,
  &__actions {
    padding: var(--spacing-m);

    @include mq($until: tablet) {
      padding: calc(var(--spacing-m) / 1.5);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    line-height: 1.1;
    font-weight: 900;
    letter-spacing: .5px;
    text-transform: uppercase;
    font-size: 1.25rem;
    background: #e9e9e9;
    border-radius: 6px 6px 0 0;
    color: var(--color-primary-dark);
  }

  &__content {
    padding: var(--spacing-m);

    @include mq($until: tablet) {
      padding: calc(var(--spacing-m) / 1.5);
    }
  }

  &__actions {
    background: #e9e9e9;
    border-radius: 0 0 6px 6px;
  }
}

.user-consents-form {
  padding: 1rem;
}

.user-consents-action {
  text-align: center;
  margin-top: 2rem;
}
.user-consents-flag {
  a{
    text-decoration: underline;
  }
}